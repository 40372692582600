.customer-retention {
  width: 90%;
  /* background-color: #FFA400; */
  /* margin: auto; */
}

.customer-retention-container {
  margin: 2rem 0rem;
}

.why-ys {
  text-align: center;
  margin-left: 12rem;

}

.why-ys h1 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  color: rgb(90, 100, 111);
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 0.8);
  display: inline-block;
}

.why-ys h1:hover {
  letter-spacing: 0.2rem;
  color: #FFA400;
}

.ys-points {
  width: 100%;
  /* margin: auto; */
  margin: 0rem 8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  position: relative;
  padding: 2rem;
  border: 5px dotted gray;
  border-radius: 28px;
  /* box-sizing: border-box; */
}

.item {
  width: 35%;
  margin: auto;
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  opacity: 0;
  position: relative;
}

.item:nth-child(odd) {
  align-self: flex-start;
}

.item:nth-child(even) {
  align-self: flex-end;
}

.item::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: rgb(164, 161, 161);
  border: 2px solid rgb(188, 187, 187);
  border-radius: 50px;
  top: 50%;
  left: -123px;
  /* Adjusted to align with the dashed border */
  transform: translateY(-50%);
  z-index: 1;
}

.item:nth-child(even)::before {
  left: auto;
  right: -123px;
  /* Adjusted to align with the dashed border */
}

.ys-point-para h4 {
  font-size: 3rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  color: rgb(65, 64, 66);
  text-align: center;
}

.ys-point-para:last-child {
  /* font-size: 2rem; */
  /* font-weight: 700; */
  margin-bottom: 2rem;
  font-family: Poppins, sans-serif;
  color: rgb(65, 64, 66);
  text-align: center;
}

.ys-point-para p {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color:#34404c;
  text-align: center;
}


.ys-img {
  width: 45rem;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ys-img img {
  width: 65%;
  height: 100%;
}

@keyframes slideInTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-top {
  animation: slideInTop 1s forwards;
}

.slide-bottom {
  animation: slideInBottom 1s forwards;
}

/* Media Queries for Responsiveness */
@media (max-width: 1440px) {

  .item::before {
    left: -75px;
  }

  .item:nth-child(even)::before {
    right: -75px;
  }

  .ys-img {
    width: 13rem;
    height: 13rem;
    margin: auto;
  }

  .ys-img img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1200px) {

  .ys-img {
    width: 15rem;
    height: 15rem;
    margin: auto;
  }

  .ys-img img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .item {
    width: 80%;
  }

  .item::before {
    left: -110px;
  }

  .item:nth-child(even)::before {
    right: -110px;
  }

  .ys-img {
    width: 15rem;
    height: 15rem;
  }

  .ys-img img {
    width: 100%;
    height: 100%;
  }

  .ys-points {
    width: 100%;
    /* margin: auto; */
    margin: 0rem 8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    margin-left: 6rem;
    position: relative;
    padding: 2rem;
    border: 5px dotted gray;
    border-radius: 28px;
  }
}

@media (max-width: 768px) {
  .why-ys h1 {
    font-size: 2rem;
  }

  .ys-points {
    width: 88%;
    /* margin: auto; */
    margin: 0rem 8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    position: relative;
    padding: 2rem;
    border: 5px dotted gray;
    border-radius: 28px;
    /* box-sizing: border-box; */
  }

  .item {
    width: 100%;
  }

  .item::before {
    left: -47px;
  }

  .item:nth-child(even)::before {
    right: -47px;
  }

  .ys-point-para h4 {
    font-size: 1.5rem;
  }

  .ys-point-para p {
    font-size: 1rem;
  }

  .ys-img {
    width: 15rem;
    height: 10rem;
  }
}

@media (max-width: 576px) {
  .why-ys h1 {
    font-size: 1.5rem;
  }

  .item::before {
    left: -47px;
  }

  .item:nth-child(even)::before {
    right: -47px;
  }

  .ys-point-para h4 {
    font-size: 1.25rem;
  }

  .ys-point-para p {
    font-size: 0.875rem;
  }

  .ys-img {
    width: 15rem;
    height: 10rem;
  }

  .ys-points {
    width: 80%;
    /* margin: auto; */
    margin: 0rem 8rem;
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    position: relative;
    padding: 2rem;
    border: 5px dotted gray;
    border-radius: 28px;
  }
}

@media (max-width: 500px) {
  .customer-retention-container {
    /* width: 115%; */
    width: 30rem;
  }

  .item {
    width: 90%;
    padding: 0rem;
  }

  .why-ys h1 {
    font-size: 1.5rem;
  }

  .item::before {
    left: -60px;
  }

  .item:nth-child(even)::before {
    right: -60px;
  }

  .ys-point-para h4 {
    font-size: 1.25rem;
  }

  .ys-point-para p {
    font-size: 0.875rem;
  }

  .ys-img {
    width: 12rem;
    height: 10rem;
  }

  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 6rem;
  }
}

@media (max-width: 450px) {
  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 13rem;
  }

  .why-ys {
    text-align: center;
    margin-left: 7rem;
  }

  .customer-retention-container {
    /* width: 115%; */
    width: 30rem;
    margin-left: -4rem;
  }

  .customer-retention {
    width: 150%;
    margin-left: -2rem;
    /* margin: auto; */
  }

}

@media (max-width: 400px) {
   .customer-retention-container{
    width: 105%;  
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
   }
 
   .ys-img {
    width: 50rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ys-img img {
    width: 100%;
    height: 100%;
  }
  
  .item {
    width: 94%;
  }

  .item::before {
    left: -80px;
  }

  .item:nth-child(even)::before {
    right: -80px;
  }

  .animated-text {
    /* width: 80%; */
    width: 80%;
    margin-right: -1rem;
    animation-name: textAnimation;
    animation-duration: 2s;
    animation-iteration-count: 2;
    animation-fill-mode: both;
  }
/* 
  .item {
    width: 45%;
    margin: auto;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    opacity: 0;
    position: relative;
  } */

  .why-ys {
    text-align: center;
    margin-left: 5rem;
  }

  .why-ys h1 {
    font-size: 3rem;
    margin-right: 2rem;
  }

  .ys-img {
    width: 18rem;
    height: 18rem;
  }

  .ys-point-para h4 {
    font-size: 1.5rem;
  }

  .ys-point-para p {
    font-size: 1.1rem;
  }
}

@media (max-width: 350px) {
  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 3rem;
  }

  .customer-retention-container {
    /* width: 115%; */
    width: 30rem;
    margin-left: 1rem;
  }

  .why-ys h1 {
    font-size: 1.6rem;
    margin-right: 12rem;
  }

  .why-ys {
    text-align: center;
    margin-left: -10rem;
  }

  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 3.5rem;
  }
}

@media (max-width: 300px) {
  .customer-retention-container {
    /* width: 115%; */
    /* width: 23rem; */
    width: 27rem;
  }

  .ys-points {
    margin: 2rem 1rem;
  }

  .why-ys {
    text-align: center;
    margin-left: 16rem;
  }

  .why-ys h1 {
    font-size: 1.8rem;
    margin-left: -15rem;
  }

  .ys-img {
    width: 15rem;
    height: 15rem;
  }

  .ys-point-para h4 {
    font-size: 1.5rem;
  }

  .ys-point-para p {
    margin-top: 1rem;
    font-size: 1.1rem;
    /* text-align: justify; */
  }
}