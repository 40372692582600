/* grid code */
.Contact-us {
  background-color: #253755;
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  border-radius: 25px;
  gap: 2rem;
}

.contact-container {
  margin: 2rem 0;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.address-detail {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.ls-contact-banner {
  width: 100%;
  padding: 1rem;
  text-align: center;
}

.ls-contact-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: Poppins, sans-serif;
  color: white;
  margin-top: 6rem;
}

.register-add h5 {
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-top: 5rem;
  color: white;
}

.register-add p {
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: white;
}

.office-add h5 {
  font-size: 1.8rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: white;
}

.office-add p {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: white;
}

.contact-form {
  width: 100%;
  padding: 1rem;
}

.input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.Contact-us-banner h1 {
  font-size: 1.8rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #343333;
}

.ContactForm-submit {
  background-color: #ddd;
  border: none;
  border-radius: 8px;
  color: black;
  padding: 0.6rem 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  transition: 0.3s;
  display: inline-block;
}

.ContactForm-submit:hover {
  background-color: #3e8e41;
  color: white;
}

.input-fields input {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  border-radius: 10px;
  outline: none;
  font-size: 1.4rem;
}

.text-area textarea {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  outline: none;
  font-size: 1.5rem;
  border: 1px solid gray;
  border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
  .Contact-us {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  .ls-contact-banner,
  .contact-form {
    width: 100%;
    padding: 1rem 0;
  }

  .ls-contact-content h1 {
    font-size: 2rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.6rem;
  }

  .register-add p,
  .office-add p {
    font-size: 1.2rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .Contact-us {
    padding: 1rem;
  }

  .ls-contact-content h1 {
    font-size: 1.8rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.4rem;
  }

  .register-add p,
  .office-add p {
    font-size: 1rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
  }

  .ContactForm-submit {
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .Contact-us {
    padding: 0.5rem;
  }

  .ls-contact-content h1 {
    font-size: 1.5rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.2rem;
  }

  .register-add p,
  .office-add p {
    font-size: 0.9rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
    padding: 1rem;
  }

  .ContactForm-submit {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
  }

  .address-detail {
    gap: 3rem;
  }

  .contact-details {
    gap: 3rem;
  }

  .register-add h5 {
    margin-top: 2rem;
  }
}

@media (max-width: 400px) {
  .ls-contact-content h1 {
    font-size: 1.5rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.1rem;
  }

  .register-add p,
  .office-add p {
    font-size: 0.9rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
    padding: 0.7rem;
  }

  .ContactForm-submit {
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
  }

  .Contact-us {
    /* width: 100%; */
    width: 31rem;
    padding: 1.5rem;
    /* margin-left: 5rem; */
    /* margin: 2rem 6rem; */
  }
}

@media only screen and (max-width: 450px) {

  .ls-contact-content h1 {
    font-size: 1.5rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.2rem;
  }

  .register-add p,
  .office-add p {
    font-size: 0.9rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
    padding: 1rem;
  }

  .ContactForm-submit {
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
  }

  .address-detail {
    gap: 3rem;
  }

  .contact-details {
    gap: 3rem;
  }

  .register-add h5 {
    margin-top: 2rem;
  }

  .Contact-us {
    padding: 1.5rem;
  }
}

@media (max-width: 400px) {
  .ls-contact-content h1 {
    font-size: 1.5rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.1rem;
  }

  .register-add p,
  .office-add p {
    font-size: 0.9rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
    padding: 0.7rem;
  }

  .Contact-us {
    /* width: 100%; */
    width: 31rem;
    padding: 1.5rem;
    /* margin-left: 5rem; */
    /* margin: 2rem 6rem; */
  }

  .ContactForm-submit {
    padding: 0.4rem 1.3rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {

  .Contact-us {
    /* width: 100%; */
    width: 28rem;
    padding: 1.5rem;
  }

  .ContactForm-submit {
    padding: 0.4rem 1rem;
    font-size: rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 300px) {

  .Contact-us {
    /* width: 100%; */
    /* width: 30rem; */
    width: 25rem;
    padding: 1.5rem;
    /* margin-left: 5rem; */
    /* margin: 2rem 6rem; */
  }

  .ContactForm-submit {
    padding: 0.4rem 1rem;
    font-size: 1rem;
  }

  .ls-contact-content h1 {
    font-size: 1.5rem;
  }

  .register-add h5,
  .office-add h5 {
    font-size: 1.1rem;
  }

  .register-add p,
  .office-add p {
    font-size: 0.9rem;
  }

  .input-fields input,
  .text-area textarea {
    max-width: 100%;
    padding: 0.7rem;
  }
}