.App-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-bottom: 1px solid rgb(221, 221, 221);
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: white;
  height: 8rem;
}

.scrolled {
  background-color: #fff;
}

.app-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15rem;
  margin-top: 1rem;
}

.logo {
  width: 23.4rem;
  height: 6rem;
  padding: 1rem;
  margin-left: 8rem;
}

.npm img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.nav-bar {
  display: flex;
  align-items: center;
  gap: 5rem;
  flex-wrap: wrap;
  transition: all 0.3s ease;
}

.nav-bar li {
  position: relative;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 600;
  color: #323C55;
  font-family: 'Inter', sans-serif !important;
}

.nav-bar li a.active {
  background-color: #3071FE;
  color: white;
  padding: 8px 15px;
  border-radius: 8px;
}

.nav-bar li::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 3px;
  background-color: #333d57;
  transition: width 0.3s ease-in-out;
}

.nav-bar li:hover::after {
  width: 100%;
}

.scrolled li {
  color: black;
}

.demo-btn a {
  font-size: 1.8rem;
  font-weight: 600;
  background: #434e6c;
  color: #fff;
  border: 0.25rem solid #434e6c;
  border-radius: 8px;
  padding: 0.30rem 0.60rem;
  margin: 1rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
}

.demo-btn a:hover {
  color: #434e6c;
}

.demo-btn a.slide_from_right::after {
  top: 0;
  bottom: 0;
  left: 100%;
  right: -100%;
}

.demo-btn a::after {
  content: "";
  background: #ecf0f1;
  position: absolute;
  top: 0;
  z-index: -1;
  transition: all 0.35s;
}

.demo-btn a.slide_from_right:hover::after {
  left: 0;
  right: 0;
}

.container>div {
  margin-top: 80px;
}

.hamburger {
  display: none;
}

@media (min-width:300px) and (max-width: 400px) {
  .App-header {
    width: 100%;
    height: 3rem;
  }

  .app-container {
    width: 100%;
  }

  .logo {
    width: 10rem;
    height: 3rem;
    padding: 0.25rem;
    margin-left: 0;
  }

  .nav-bar {
    gap: 0.5rem;
  }

  .nav-bar li {
    font-size: 0.8rem;
  }

  .demo-btn a {
    font-size: 0.4rem;
    padding: 0.2rem 0.4rem;
  }

  .hamburger {
    display: block;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .App-header {
    height: 5rem;
    width: 100%;
  }

  .app-container {
    width: 100%;
  }

  .logo {
    width: 8rem;
    height: 3rem;
    padding: 0.5rem;
    margin-left: 0;
  }

  .nav-bar {
    gap: 1rem;
    flex-direction: column;
  }

  .nav-bar li {
    font-size: 1rem;
  }

  .demo-btn a {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
  }

  .hamburger {
    display: block;
    font-size: 2rem;
    cursor: pointer;
    margin-left: auto;
  }
}

@media (max-width: 1024px) {
  .app-container {
    width: 90%;
    gap: 3rem;
  }

  .logo {
    width: 16rem;
    height: 5rem;
  }

  .nav-bar {
    gap: 5rem;
  }

  .nav-bar li {
    font-size: 1.3rem;
  }

  .demo-btn a {
    font-size: 0.9em;
  }
}

@media (max-width: 768px) {

  /* .app-container {
    width: 100%;
    margin: auto;
    flex-direction: row;
    gap: 1.5rem;
  } */
  .app-container {
    width: 100%;
    margin: auto;
    margin-top: 1.5rem;
    flex-direction: row;
    gap: 1.5rem;
    margin-left: -4rem;
  }

  .logo {
    width: 12rem;
    height: 5rem;
  }


  .nav-bar {
    gap: 0.7rem;
  }

  .nav-bar li {
    font-size: 1.8rem;
  }

  .demo-btn a {
    font-size: 0.8em;
    padding: 0.3rem 0.6rem;
  }

  .hamburger {
    display: none;
  }
}

@media (max-width: 576px) {

  .app-container {
    width: 110%;
    flex-direction: row;
    justify-content: space-between;
  }

  .nav-bar {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 5rem;
    left: 0;
    padding: 1rem 0;
    border-top: 1px solid #ddd;
    transition: all 0.3s ease;
  }

  .nav-bar.open {
    display: flex;
  }

  .nav-bar li {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
  }

  .nav-bar li::after {
    display: none;
  }

  .demo-btn {
    width: 40%;
    font-size: 1.4rem;
    text-align: center;
  }

  .demo-btn a {
    /* font-size: 0.8em; */
    padding: 0.3rem 0.6rem;
    margin-top: -0.1rem;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    color: #22283a;
    background: none;
    border: none;
    font-size: 2.5rem;
    padding: 1rem;
    /* margin-top: -0.4rem; */
    margin-top: -1.6rem;
    margin-left: 1.6rem;
  }

  .hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    background-color: #282727;
  }

  .logo {
    width: 9rem;
    height: 3rem;
    padding: 0.5rem;
    margin-left: 0;
  }

  .App-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 1px solid rgb(221, 221, 221);
    background-color: rgba(255, 255, 255, 0.5);
    /* height: 6rem; */
    height: 6rem;
  }
}

@media (max-width: 450px) {

  .app-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -2rem;
  }

  .dashboardTwo-block {
    top: 30rem;
    left: -3rem;
  }

  .dashboard-blocks {
    top: 5rem;
    left: 4rem;
  }

  .logo {
    width: 19rem;
    height: 4.5rem;
    padding: 0.5rem;
    margin-left: 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    color: #22283a;
    background: none;
    border: none;
    font-size: 2.5rem;
    padding: 1rem;
    /* margin-top: -0.4rem; */
    margin-top: -1.8rem;
    margin-left: 2rem;
  }

  .demo-btn a {
    /* font-size: 0.8em; */
    padding: 0.3rem 0.6rem;
    margin-top: 0.3rem;
  }

  .App-header {
    background-color: white;
  }
}

@media (max-width: 400px) {

  .app-container {
    width: 115%;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    width: 18rem;
    height: 4rem;
    padding: 0.5rem;
    margin-left: 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    color: #22283a;
    background: none;
    border: none;
    font-size: 2.5rem;
    padding: 1rem;
    /* margin-top: -0.4rem; */
    margin-top: -2.1rem;
    margin-left: 1.6rem;
  }

  .logo {
    width: 16rem;
    height: 4rem;
    padding: 0.5rem;
    margin-left: -60px;
    margin-bottom: 1rem;
  }

  .demo-btn a {
    /* font-size: 0.8em; */
    padding: 0.3rem 0.6rem;
    margin-top: -0.2rem;
  }

  .App-header {
    background-color: white;
  }
}

@media (max-width: 350px) {
  .app-container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -1rem;
  }

  .demo-btn a {
    font-size: 0.9rem;
    padding: 0.3rem 0.6rem;
    margin-bottom: rem;
    margin-bottom: 1rem;
    margin-top: 0.1rem;
    margin-left: -3rem;
  }

  .hamburger {
    gap: 2rem;
    display: block;
    cursor: pointer;
    color: #22283a;
    background: none;
    border: none;
    font-size: 2.2rem;
    padding: 1rem;
    margin-bottom: 3rem;
    margin-left: 0.8rem;
    margin-top: -1.4rem;
  }

  .logo img {
    width: 80%;
    height: 100%;
    cursor: pointer;
  }

  .demo-btn {
    width: 80%;
    font-size: 1.4rem;
    text-align: center;
  }

  .nav-bar {
    display: none;
    flex-direction: column;
    width: 90%;
    background-color: #fff;
    position: absolute;
    top: 6rem;
    left: 0px;
    padding: 1rem 0;
    border-top: 1px solid #ddd;
    transition: all 0.3s ease;
  }

  .nav-bar li {
    font-size: 1.4rem;
  }

  .logo {
    width: 30rem;
    height: 4rem;
    margin-bottom: 2.5rem;
    margin-top: -0.5rem;
    margin-left: -2rem;
  }

  .demo-btn a {
    font-size: 1.1rem;
    padding: 0.4rem 0.8rem;
    margin-bottom: rem;
    margin-top: 0.1rem;
    margin-left: -3rem;
  }

  .App-header {
    /* width: 125%; */
    width: 115%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 1px solid rgb(221, 221, 221);
    /* background-color: rgba(255, 255, 255, 0.5); */
    /* height: 6rem; */
    height: 6rem;
    background-color: white;
    /* margin-right: 2rem; */
  }

}

@media (max-width: 300px) {

  .logo img {
    width: 80%;
    height: 70%;
    cursor: pointer;
  }

  .hero-section-title h3 {
    font-size: 2rem;
    padding: 0.3rem;
    margin-top: 0rem;
    line-height: 3rem;
  }

  .logo {
    width: 35rem;
    height: 5rem;
    margin-bottom: 1rem;
    margin-left: -2rem;
  }

  .hero-section-title h5 {
    font-size: 1.1rem;
    padding: 0.5rem;
  }

  .demo-btn {
    width: 200%;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .demo-btn a {
    font-size: 0.9rem;
    padding: 0.3rem 0.6rem;
    margin-bottom: -0.5rem;
  }

  .app-container {
    width: 100%;
    display: flex;
    align-items: center;
    /* flex-direction: row; */
    justify-content: space-between;
  }


  .hamburger {
    gap: 2rem;
    display: block;
    cursor: pointer;
    color: #22283a;
    background: none;
    border: none;
    font-size: 2.2rem;
    padding: 1rem;
    margin-bottom: 3rem;
    margin-left: 1.1rem;
    margin-top: -1.1rem;
  }

  .nav-bar {
    display: none;
    flex-direction: column;
    width: 90%;
    background-color: #fff;
    position: absolute;
    top: 6rem;
    left: 0px;
    padding: 1rem 0;
    border-top: 1px solid #ddd;
    transition: all 0.3s ease;
  }

  .App-header {
    /* width: 125%; */
    width: 115%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 1px solid rgb(221, 221, 221);
    /* background-color: rgba(255, 255, 255, 0.5); */
    /* height: 6rem; */
    height: 6rem;
    background-color: white;
    /* margin-right: 2rem; */
  }
}