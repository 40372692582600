/* Base styles */
* {
    margin: 0;
    padding: 0;
}

.header {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(161, 185, 185);
    padding: 1rem;
}

.header h1 {
    font-size: 1.3rem;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
}

.right-side {
    display: flex;
    align-items: center;
}

.right-side p {
    padding: 0rem 1rem;
}

.refundpolicy-container {
    width: 90%;
    margin: auto;
}

.mt-30 {
    margin-top: 3.5rem;
}

.mtb-30 {
    margin: 3rem 0px;
}

.ptb-10 {
    padding: 1rem 0rem;
}

.ptb-15 {
    padding: 1.5rem 0rem;
}

.ptb-20 {
    padding: 2rem 0rem;
}

.contact-bold {
    font-weight: 800;
}

.refundpolicy-container h1 {
    font-size: 1.1rem;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.refundpolicy-container h4 {
    font-size: 1.1rem;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.refundpolicy-container h5 {
    font-size: 1.1rem;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.refundpolicy-container p {
    font-size: 1rem;
    font-weight: 400;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(32, 38, 44);
}

.refundpolicy-container a {
    cursor: pointer;
}

.refundpolicy-container a:hover {
    cursor: pointer;
    color: orange;
}

/* Media Queries */

/* Tablets */
@media (max-width: 1024px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem;
    }

    .header h1 {
        font-size: 1.2rem;
    }

    .right-side {
        flex-direction: column;
        align-items: flex-start;
    }

    .right-side p {
        padding: 0.5rem 0;
    }

    .refundpolicy-container {
        width: 95%;
    }

    .refundpolicy-container h1,
    .refundpolicy-container h4,
    .refundpolicy-container h5 {
        font-size: 1rem;
    }

    .refundpolicy-container p {
        font-size: 0.9rem;
    }
}

/* Mobile devices */
@media (max-width: 767px) {
    .header {
        flex-direction: row;
        padding: 0.5rem;
    }

    .header h1 {
        font-size: 1.1rem;
    }

    .right-side {
        flex-direction: column;
       
    }

    .right-side p {
        padding: 0.5rem 0;
    }

    .refundpolicy-container {
        width: 100%;
        padding: 1rem;
    }

    .refundpolicy-container h1,
    .refundpolicy-container h4,
    .refundpolicy-container h5 {
        font-size: 0.9rem;
    }

    .refundpolicy-container p {
        font-size: 0.8rem;
    }

    .mt-30 {
        margin-top: 2rem;
    }

    .mtb-30 {
        margin: 2rem 0px;
    }

    .ptb-10 {
        padding: 0.5rem 0rem;
    }

    .ptb-15 {
        padding: 1rem 0rem;
    }

    .ptb-20 {
        padding: 1.5rem 0rem;
    }
}
