body{
   background-color: rgb(255 255 255 / 1.0); 
}

/* .container{
   width:90%;
   margin: auto;
 } */

 /* .mt-3{
   margin-top: 3rem;
 } */
 
 
 body {
  overflow-x: hidden; /* Add this to prevent horizontal scrolling */
}