/* Base styles */
.pricing {
    width: 100%;
    /* width: 550px; */
    margin-bottom: 3rem;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(131, 168, 219, 1) 100%, rgba(148, 141, 223, 1) 100%);
    padding: 2rem;
    margin: 2rem 0rem;
}

.pricing-banner {
    width: 90%;
    margin: auto;
}

.d-flex {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mtb-2 {
    margin: 2rem 0rem;
}

.pricing-title {
    width: 90%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.pricing-title h1 {
    font-size: 2rem;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 600;
    font-style: oblique;
    text-align: center;
    color: #fff;
}

.pricing-title h4 {
    font-size: 18px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
    text-align: center;
}

.price-card {
    width: 30%;
    background-color: #fff;
    box-shadow: 4px 4px 5px slategray;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 8px;
}

.price-heading {
    border-bottom: 1px solid rgb(20, 20, 21);
    padding: 5px 10px;
}

.price-heading h5 {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    text-align: center;
    color: rgb(91, 91, 173);
}

.price-heading p {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: rgb(91, 91, 173);
}

.price-heading span {
    font-weight: 600;
    color: black;
}

.price-body {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid rgb(63, 63, 69);
}

.price-body span {
    color: rgb(45, 46, 46);
}

.price-body h5 {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.price-body span {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.buy-btn button {
    padding: 15px 5px;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
    background-color: rgb(108, 108, 208);
    color: white;
    border-radius: 5px;
}

.buy-btn {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive styles */

/* For screens between 1200px and 1024px */
@media (max-width: 1200px) and (min-width: 1024px) {
    .price-card {
        width: 45%;
    }

    .pricing-title h1 {
        font-size: 1.8rem;
    }

    .pricing-title h4 {
        font-size: 16px;
    }

    .price-heading h5 {
        font-size: 1rem;
    }

    .price-heading p {
        font-size: 20px;
    }

    .price-body h5,
    .price-body span {
        font-size: 1rem;
    }

    .buy-btn button {
        font-size: 14px;
    }
}

/* For screens between 1024px and 768px */
@media (max-width: 1024px) and (min-width: 768px) {
    .price-card {
        width: 45%;
    }

    .pricing-title h1 {
        font-size: 1.6rem;
    }

    .pricing-title h4 {
        font-size: 16px;
    }

    .price-heading h5 {
        font-size: 1rem;
    }

    .price-heading p {
        font-size: 18px;
    }

    .price-body h5,
    .price-body span {
        font-size: 1rem;
    }

    .buy-btn button {
        font-size: 14px;
    }
}

/* For screens between 767px and 480px */
@media (max-width: 767px) and (min-width: 480px) {
    .pricing {
        width: 100%;
    }

    .price-card {
        width: 90%;
    }

    .pricing-title h1 {
        font-size: 1.4rem;
    }

    .pricing-title h4 {
        font-size: 14px;
    }

    .price-heading h5 {
        font-size: 0.9rem;
    }

    .price-heading p {
        font-size: 16px;
    }

    .price-body h5,
    .price-body span {
        font-size: 0.9rem;
    }

    .buy-btn button {
        font-size: 12px;
    }

    .flex-between {
        flex-direction: column;
        gap: 2rem;
    }
}

/* For screens between 480px and 360px */
@media (max-width: 480px) {
    .pricing {
        width: 100%;
    }

    .price-card {
        width: 60%;
    }

    .flex-between {
        flex-direction: column;
        gap: 2rem;
    }

    .pricing-title h1 {
        font-size: 1.2rem;
    }

    .pricing-title h4 {
        font-size: 12px;
    }

    .price-heading p {
        font-size: 14px;
    }

    .price-body h5,
    .price-body span {
        font-size: 0.8rem;
    }

    .buy-btn button {
        font-size: 10px;
    }

    .price-heading h5 {
        font-size: 1.2rem;
    }

    .price-body-title h5 {
        font-size: 1.2rem;
    }

    .d-flex span {
        font-size: 1.2rem;
    }

    .d-flex h5 {
        font-size: 1.2rem;
    }
}

/* For screens 360px and below */
@media (max-width: 360px) {
    .price-card {
        width: 100%;
    }

    /* 
    .pricing-title h1 {
        font-size: 1rem;
    } */

    .pricing-title h4 {
        font-size: 10px;
    }

    .price-heading p {
        font-size: 12px;
    }

    .price-body h5,
    .price-body span {
        font-size: 0.7rem;
    }

    .flex-between {
        flex-direction: column;
        gap: 2rem;
    }

    .buy-btn button {
        font-size: 8px;
    }

    .price-heading h5 {
        font-size: 1.1rem;
    }

    .pricing-title h1 {
        font-size: 1.4rem;
    }

    .price-body-title h5 {
        font-size: 1.2rem;
    }

    .d-flex span {
        font-size: 1.2rem;
    }

    .d-flex h5 {
        font-size: 1.2rem;
    }
}