.buisness-banner {
  width: 100%;
  height: 60vh;
  /* Add height to ensure the background image covers the full container */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url('../../Assets/images/businessBanner.avif');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* Center the background image */
  position: relative;
  padding: 1.5rem;
  /* clip-path: inset(0 round 50px); */
}

/* .buisness-title p {
  width: 80%;
  margin-left: 16rem;
  font-size: 2rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #34404c;
} */
.buisness-title p {
  width: 80%;
  margin-left: 16rem;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #34404c;
}

.buisness-title {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
}

.buisness-title h1 {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 3.2rem;
  font-weight: 750;
  color: #414141;
  letter-spacing: -.04em;
}

.buisness-banner-logo img {
  width: 80px;
  height: 70px;
}

.buisness-banner-logo {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.buisness-banner-logo .users-logo,
.buisness-banner-logo .appointmentBook-logo,
.buisness-banner-logo .location-logo {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: #2b2c2d;
}

.contact-btn {
  text-align: center;
  padding: 1rem;
}

.btn {
  color: #FFF;
  font-size: 1.4rem;
  font-weight: 600;
  background-color: rgb(79, 111, 143);
  border-radius: 8px;
  padding: 10px 30px;
  transition: 1s ease-in-out
}

.btn1:hover {
  box-shadow: 0px 0px 0px 1px rgb(79, 111, 143), 0px 0px 0px 5px rgb(79, 111, 143), 0px 0px 0px 5px rgb(79, 111, 143), 0px 0px 0px 5px rgb(79, 111, 143);
  background-color: white;
  color: rgb(79, 111, 143);
}

@media only screen and (max-width: 768px) {
  .buisness-banner {
    height: 60vh;
    gap: 1rem;
    padding: 1rem;
  }

  .buisness-title h1 {
    font-size: 1.2rem;
  }

  .buisness-title p {
    font-size: 0.8rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }

  .contact-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .buisness-banner {
    padding: 1rem;
    height: auto;
    flex-direction: column;
    gap: 3rem;
  }

  .buisness-title {
    gap: 1rem;
  }

  .buisness-title h1 {
    font-size: 1.8rem;
  }

  .buisness-title p {
    font-size: 1rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1.5rem;
  }

  .btn {
    font-size: 1.2rem;
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .buisness-banner {
    padding: 0.5rem;
    height: auto;
  }

  .buisness-title h1 {
    font-size: 1.5rem;
  }

  .buisness-title p {
    font-size: 0.9rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .contact-btn {
    padding: 0.5rem;
  }

  .btn {
    font-size: 1rem;
    padding: 6px 16px;
  }
}

@media only screen and (max-width: 480px) {
  .buisness-banner {
    /* width: 115%; */
    width: 25rem;
    padding: 0.5rem;
    height: 60vh;
  }

  .buisness-title h1 {
    font-size: 1.2rem;
  }

  .buisness-title p {
    font-size: 0.8rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    flex-direction: column;
    text-align: center;
  }

  .contact-btn {
    padding: 0.5rem;
  }

  .btn {
    font-size: 0.8rem;
    padding: 5px 12px;
  }
}

@media only screen and (max-width: 576px) {
  .buisness-banner {
    padding: 1rem 10rem;
    gap: 1rem;
    height: 70vh;
    /* margin: 2rem 3rem; */
    margin: 2rem 0rem;
  }

  .buisness-title h1 {
    font-size: 1.2rem;
  }

  .buisness-title p {
    font-size: 0.8rem;
  }

  .buisness-banner-logo img {
    width: 50px;
    height: 50px;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }

  .contact-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .buisness-banner {
    padding: 1rem;
    gap: 1rem;
    height: 70vh;
    width: 300px;
    /* margin: 2rem 3rem; */
    /* margin: 2rem 5rem; */
    margin: 2rem 6.3rem;
}

  .buisness-title h1 {
    font-size: 1.2rem;
  }

  .buisness-title p {
    font-size: 1rem;
    margin-left: 2rem;
  }

  .buisness-banner-logo img {
    width: 50px;
    height: 50px;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }

  .contact-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 10.3rem;
  }
}

@media only screen and (max-width: 400px) {
  .buisness-banner {
    width: 100%;
    margin: auto;
    padding: 1rem;
    gap: 1rem;
    height: 50vh;
    /* height: 65vh; */
  }

  /* .ys-points {
    width: 30rem;
    margin: 2rem 8.5rem;
  } */

  .buisness-banner-logo img {
    width: 20px;
    height: 20px;
  }

  .buisness-title h1 {
    font-size: 1.5rem;
  }

  .ys-points {
    width: 30rem;
    margin: 2rem 10.7rem;
  }

  .buisness-title p {
    font-size: 1rem;
    margin-left: 2rem;
  }
}

@media only screen and (max-width: 350px) {
  .buisness-banner {
    padding: 1rem;
    gap: 1rem;
    height: 60vh;
    /* margin: 2rem 3rem; */
    margin: 2rem 4rem;
  }

  .buisness-banner {
    padding: 1rem;
    gap: 1rem;
    height: 100vh;
    /* margin: 2rem 3rem; */
    margin: 2rem 4.8rem;
  }

  .buisness-title h1 {
    font-size: 1.5rem;
  }

  .buisness-title p {
    font-size: 1.1rem;
    margin-left: 2rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
  }

  .ys-points {
    /* margin: 2rem 4rem; */
    margin: 2rem 3rem;
  }

}

@media only screen and (max-width: 300px) {

  .buisness-banner {
    padding: 2rem;
    gap: 1rem;
    height: 100vh;
    /* margin: 2rem 3rem; */
    margin: 2rem 2rem;
  }

  .ys-points {
    width: 25rem;
    /* margin: 2rem 4rem; */
    margin: 2rem 3.5rem;
  }

  .buisness-title p {
    font-size: 1.1rem;
    margin-left: 2rem;
    text-align: justify;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }

  .contact-btn button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .buisness-banner {
    padding: 1rem;
    gap: 1rem;
    height: 100vh;
    /* margin: 2rem 3rem; */
    margin: 2rem 2rem;
  }

  .buisness-title h1 {
    font-size: 1.4rem;
  }

  .buisness-banner-logo .users-logo,
  .buisness-banner-logo .appointmentBook-logo,
  .buisness-banner-logo .location-logo {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
  }

  .buisness-banner-logo img {
    width: 40px;
    height: 40px;
  }
}