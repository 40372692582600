/* Base styles */
* {
    margin: 0;
    padding: 0;
}

.headers {
    width: 100%;
    background-color: rgb(161, 185, 185);
}

.term-condition-container {
    width: 90%;
    margin: auto;
}

.mt-30 {
    margin-top: 4rem;
}

.mb-40 {
    margin-bottom: 3rem;
}

.mtb-30 {
    margin: 3rem 0px;
}

.ptb-10 {
    padding: 1rem 0rem;
}

.ptb-15 {
    padding: 1.5rem 0rem;
}

.ptb-20 {
    padding: 2rem 0rem;
}

.term-condition-container p {
    font-size: 1rem;
    color: rgb(45, 44, 44);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
}

.term-condition-container ul {
    list-style: disc;
    padding: 1rem;
    line-height: 30px;
}

.term-condition-container ul li {
    color: rgb(54, 53, 53);
    font-size: 1rem;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Media Queries */

/* Tablets */
@media (max-width: 1024px) {
    .term-condition-container {
        width: 95%;
    }

    .term-condition-container p {
        font-size: 0.9rem;
    }

    .term-condition-container ul li {
        font-size: 0.9rem;
    }

    .mt-30 {
        margin-top: 3rem;
    }

    .mb-40 {
        margin-bottom: 2.5rem;
    }

    .mtb-30 {
        margin: 2.5rem 0px;
    }

    .ptb-10 {
        padding: 0.8rem 0rem;
    }

    .ptb-15 {
        padding: 1.2rem 0rem;
    }

    .ptb-20 {
        padding: 1.6rem 0rem;
    }
}

/* Mobile devices */
@media (max-width: 767px) {
    .term-condition-container {
        width: 100%;
        padding: 1rem;
    }

    .term-condition-container p {
        font-size: 0.8rem;
    }

    .term-condition-container ul {
        padding: 0.8rem;
    }

    .term-condition-container ul li {
        font-size: 0.8rem;
    }

    .mt-30 {
        margin-top: 2rem;
    }

    .mb-40 {
        margin-bottom: 2rem;
    }

    .mtb-30 {
        margin: 2rem 0px;
    }

    .ptb-10 {
        padding: 0.5rem 0rem;
    }

    .ptb-15 {
        padding: 0.9rem 0rem;
    }

    .ptb-20 {
        padding: 1.2rem 0rem;
    }
}
