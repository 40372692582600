.footer-container {
  width: 100%;
  /* background-image: url('../../Assets/images/new-trend.webp'); */
  /* background:linear-gradient(#000,#fff,#000,45deg); */
  background: linear-gradient(45deg, #000, #3f3b3b, #000);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1rem;
}

.footer {
  width: 90%;
  height: 50vh;
  /* Set height to auto for better responsiveness */
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  /* Adjusted gap for uniform spacing */
}

/* .footer-logo img {
  width: 100%;
  max-width: 18rem;
  height: 6rem;
} */
.footer-logo img {
  width: 100%;
  max-width: 22rem;
  height: 6rem;
}

.second-col {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.heading h1 {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.whatsapp-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.link-ys {
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.social-media {
  display: flex;
  gap: 2rem;
}

.whatsapp-number {
  font-size: 1.5rem;
  color: white;
}

.link-ys {
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.quick-links {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.quick-heading {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.TandC {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.privacy-policy {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.privacy-policy span {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
}

.banner-section {
  display: flex;
  align-items: center;
}

.copy-right {
  color: white;
  text-align: center;
  /* font-size: 1.2rem; */
  font-size: 1.6rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: auto;
  width: 100%;
}

@keyframes bling {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }

  50% {
    transform: scale(1.4);
    filter: brightness(1.5);
  }

  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

.whatsapp-logo .icon-logo {
  animation: bling 2s infinite;
}


@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    gap: 3rem;
  }

  .footer-logo img {
    width: 15rem;
  }

  .copy-right {
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  .footer {
    width: 90%;
    height: 70vh;
    flex-direction: column;
    padding: 2rem;
  }

  .footer-logo img {
    width: 15rem;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .footer {
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
  }

  .footer-logo img {
    width: 10rem;
    height: 3.5rem;
  }

  .footer-girl img {
    width: 15rem;
    height: auto;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.2rem;
  }

  .privacy-policy span {
    font-size: 1rem;
  }

  .botton-line {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .footer-container {
    /* width: 550px; */
    width: 600px;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    height: auto;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.5rem;
  }

  .footer-logo img {
    width: 10rem;
    height: auto;
  }

  .footer-girl img {
    width: 20rem;
    height: auto;
  }

  .social-media {
    gap: 1rem;
  }

  .privacy-policy span {
    font-size: 1rem;
  }

  .botton-line {
    font-size: 1rem;
    margin-top: 2rem;
  }
}

@media (max-width: 450px) {
  .footer-container {
    /* width: 550px; */
    width: 45rem;
  }

  .whatsapp-number,
  .link-ys {
    font-size: 1.2rem;
  }

  .privacy-policy span {
    font-size: 1.2rem;
  }

  .botton-line {
    font-size: 1.2rem;
    margin-top: 2rem;
  }

  .footer-logo img {
    width: 16rem;
    height: auto;
  }

}

@media (max-width: 400px) {
  .footer-container {
    width: 40rem;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    height: auto;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.5rem;
  }

  .footer-logo img {
    width: 10rem;
    height: auto;
  }

  .footer-girl img {
    width: 20rem;
    height: auto;
  }

  .social-media {
    gap: 1rem;
  }

  .privacy-policy span {
    font-size: 1rem;
  }

  .botton-line {
    font-size: 1rem;
    margin-top: 2rem;
  }

  .about-img {
    width: 130%;
    margin-left: -6rem;
  }

  .content-one {
    width: 90%;
    padding: 0.5rem;
  }
}

@media (max-width: 350px) {
  .footer-container {
    /* width: 117%; */
    /* width: 550px; */
    width: 35rem;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    height: auto;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.2rem;
  }

  .footer-girl img {
    width: 15rem;
    height: auto;
  }

  .social-media {
    gap: 0.5rem;
  }

  .privacy-policy span {
    font-size: 0.8rem;
  }

  .botton-line {
    font-size: 1rem;
    margin-top: 2rem;
  }

  .footer-logo img {
    width: 14rem;
    height: auto;
  }

  .whatsapp-number,
  .link-ys {
    font-size: 1.2rem;
  }

  .privacy-policy span {
    font-size: 1rem;
  }


}

@media (max-width: 300px) {
  .footer-container {
    /* width: 117%; */
    /* width: 550px; */
    width: 30rem;
  }

  .footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    height: auto;
  }

  .heading h1,
  .quick-heading {
    font-size: 1.2rem;
  }

  .footer-girl img {
    width: 15rem;
    height: auto;
  }

  .social-media {
    gap: 0.5rem;
  }

  .privacy-policy span {
    font-size: 0.8rem;
  }

  .botton-line {
    font-size: 1rem;
    margin-top: 2rem;
  }

  .footer-logo img {
    width: 14rem;
    height: auto;
  }

  .about-img {
    width: 130%;
    margin-left: -3rem;
  }

  .content-one {
    width: 100%;
    padding: 0.5rem;
  }

  .feature-content-rs {
    padding: 30px;
    margin-right: -11.5rem;
  }

  .feature-card-ls,
  .feature-card-rs {
    /* width: 90%; */
    width: 70%;
    margin-left: -1rem;
  }

}