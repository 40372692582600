.banner-2 {
  width: 90%;
  margin: auto;
  height: 90vh;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
}

.about-us {
  margin: 2rem 0rem;
}
.content-one p {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #34404c;
}

.about-top-title {
  text-align: center;
}

.about-title h1 {
  font-size: 3.5rem;
  font-weight: 700;
  color: rgb(92, 100, 111);
  font-family: Poppins, sans-serif;
}

.about-title h1:hover {
  font-size: 3.5rem;
  font-weight: 700;
  color: #FFA400;
  font-family: Poppins, sans-serif;
  text-align: center;
}

.about-para-1 h3 {
  font-size: 2.0rem;
  font-family: sans-serif;
  line-height: 1.5;
  font-weight: 600;
  color: rgb(92, 100, 111);
}

.about-img-section {
  width: 100%;
  display: flex;
  align-items: center;
}

.about-img {
  /* width: 60%; */
  width: 55%;
}

.about-content {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-radius: 16px;
  height: 80%;
}

.content-one {
  /* width: 25rem;
  padding:1rem;
  border-radius: 16px;
  background-color: #ffffff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height:100%; 
  position: relative; */
  width: 48rem;
  padding: 1rem;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  height: 50%;
  position: relative;
}

.scroll-content {
  position: absolute;
  width: 100%;
  height: 20%;
  animation: scroll 8s linear infinite;
}

@keyframes scroll {
  0% {
    top: 100%;
  }

  100% {
    top: -50%;
  }
}

@media only screen and (max-width: 992px) {
  .banner-2 {
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }

  .about-img img {
    width: 100%;
  }

  .about-content {
    width: 100%;
    padding: 0.5rem;
  }

  .about-title h1 {
    font-size: 1.5rem;
  }

  .about-title h1:hover {
    font-size: 2.5rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .about-para-1 h3 {
    font-size: 1.1rem;
  }

  .about-content p {
    font-size: 0.8rem;
  }
}

@media (max-width: 1024px) {

  .about-img-section {
    flex-direction: column;
  }

  .about-img {
    width: 90%;
    margin: auto;
  }

  .content-one {
    width: 35rem;
    height: 10rem;
  }

  .content-one p {
    font-size: 1rem;
  }

  .about-title h1:hover {
    font-size: 2.5rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .banner-2 {
    height: auto;
  }

  .about-title h1 {
    font-size: 2rem;
  }

  .about-title h1:hover {
    font-size: 2.5rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .about-para-1 h3 {
    font-size: 1.25rem;
  }

  .about-img-section {
    flex-direction: column;
    align-items: center;
  }

  .about-img {
    width: 90%;
  }

  .content-one {
    width: 30rem;
  }

  .content-one p {
    font-size: 0.9rem;
    padding: 1rem;
  }
}

@media (max-width: 576px) {
  .banner-2 {
    height: auto;
  }

  .about-title h1 {
    font-size: 1.5rem;
  }

  .about-title h1:hover {
    font-size: 2rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .about-para-1 h3 {
    font-size: 1rem;
  }

  .about-img {
    width: 100%;
  }

  .content-one {
    width: 100%;
    padding: 0.5rem;
  }

  .content-one p {
    font-size: 0.75rem;
  }
}


@media (max-width:450px) {
  .banner-2 {
    width: 30rem;
    height: auto;
  }

  .about-title h1 {
    font-size: 2.5rem;
  }

  .about-title h1:hover {
    font-size: 2rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .about-para-1 h3 {
    font-size: 1rem;
  }

  .about-img {
    width: 100%;
  }

  .content-one {
    width: 100%;
    padding: 0.5rem;
  }

  .banner-2 {
    width: 40rem;
    height: auto;
  }

  .about-para-1 h3 {
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .content-one p {
    font-size: 1.2rem;
  }
}

@media (max-width:400px) {
  .banner-2 {
    height: auto;
    width: 90%;
    margin: auto;
  }

  /* .about-title h1 {
    font-size: 1.5rem;
  } */

  .about-title h1 {
    font-size: 2rem;
  }

  .about-title h1:hover {
    font-size: 2rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .content-one {
    width: 100%;
    padding: 0.5rem;
  }

  .content-one p {
    font-size: 1rem;
  }

  .about-img {
    width: 150%;
    margin-left: -6.5rem;
  }

  .about-para-1 h3 {
    width: 90%;
    margin-left: 2rem;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

@media (max-width:350px) {
  .banner-2 {
    height: auto;
  }

  .about-title h1 {
    font-size: 2rem;
  }

  .about-para-1 h3 {
    margin-top: 1rem;
    font-size: 1.1rem;
  }

  .about-title h1:hover {
    font-size: 2rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .content-one p {
    font-size: 1.1rem;
  }

  .about-img {
    width: 100%;
  }

  .about-title {
    width: 32rem;
  }

  .about-para-1 {
    width: 32rem;
  }

  .about-img {
    width: 400px;
    margin-left: -3rem;
  }

  .content-one {
    width: 80%;
    padding: 0.5rem;
    margin-left: -6rem;
  }

  .about-img {
    width: 125%;
    margin-left: -6.5rem;
  }

  .about-content {
    width: 90%;
    padding: 0.5rem;
  }

  .about-para-1 h3 {
    width: 90%;
    margin-top: 1rem;
    font-size: 1.1rem;
    margin-left: 1.8rem;
  }
}

@media (max-width:300px) {

  .about-title h1 {
    font-size: 2rem;
  }

  .about-title {
    width: 25rem;
  }

  .about-para-1 h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .about-title h1:hover {
    font-size: 2rem;
    font-weight: 700;
    color: #FFA400;
    font-family: Poppins, sans-serif;
    text-align: center;
  }

  .about-para-1 h3 {
    font-size: 1rem;
  }

  .content-one {
    width: 100%;
    padding: 0.5rem;
  }

  /* .content-one p {
    font-size: 0.75rem;
  } */
  .content-one p {
    font-size: 1.1rem;
  }


  .banner-2 {
    /* width: 115%; */
    width: 26rem;
    /* margin: auto; */
    /* margin: 2rem 3rem; */
    /* margin: 2rem 4.8rem; */
    height: auto;
  }

  .about-img {
    width: 120%;
    margin-left: -2rem;
  }

  .about-para-1 {
    width: 24rem;
  }

  .about-content {
    width: 100%;
    margin-left: 6rem;
    padding: 0.5rem;
  }
}