/* Base styles */
.Privacy-container {
    width: 100%;
    height: 60px;
    margin: 0 auto;
    background-color: rgb(161, 185, 185);
    text-align: justify;
}

.header-privacy {
    width: 90%;
    margin: auto;
    padding: 15px;
}

.policy-body-container {
    width: 90%;
    margin: auto;
    padding: 1.5rem;
}

.policy-body-container h1 {
    font-size: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    color: rgb(20, 20, 20);
}

.policy-body-container p {
    font-size: 0.9rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    color: rgb(32, 38, 44);
    line-height: 30px;
}

.policy-body-container ul {
    list-style: disc;
    padding: 1rem;
}

.policy-body-container ul li {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: rgb(32, 38, 44);
}

.mt-30 {
    margin-top: 3.5rem;
}

.mtb-30 {
    margin: 3rem 0px;
}

.ptb-10 {
    padding: 1rem 0rem;
}

.ptb-15 {
    padding: 1.5rem 0rem;
}

.ptb-20 {
    padding: 2rem 0rem;
}

.contact-bold {
    font-weight: 800;
}

/* Media Queries */

/* Tablets */
@media (max-width: 1024px) {
    .policy-body-container {
        width: 95%;
        padding: 1rem;
    }

    .policy-body-container h1 {
        font-size: 0.95rem;
    }

    .policy-body-container p {
        font-size: 0.85rem;
        line-height: 28px;
    }

    .policy-body-container ul li {
        font-size: 0.85rem;
    }
}

/* Mobile devices */
@media (max-width: 767px) {
    .Privacy-container {
        height: auto;
        padding: 1rem 0;
        text-align: left;
    }

    .header-privacy {
        width: 95%;
        padding: 10px;
    }

    .policy-body-container {
        width: 95%;
        padding: 1rem;
    }

    .policy-body-container h1 {
        font-size: 0.9rem;
    }

    .policy-body-container p {
        font-size: 0.8rem;
        line-height: 26px;
    }

    .policy-body-container ul {
        padding: 0.5rem;
    }

    .policy-body-container ul li {
        font-size: 0.8rem;
    }

    .mt-30 {
        margin-top: 2rem;
    }

    .mtb-30 {
        margin: 2rem 0px;
    }

    .ptb-10 {
        padding: 0.5rem 0rem;
    }

    .ptb-15 {
        padding: 1rem 0rem;
    }

    .ptb-20 {
        padding: 1.5rem 0rem;
    }
}
